<template>
    <div class="warranty">
        <top title="卡号列表"></top>
        <div class="list_box">
            <div class="title">
                <div class="atv"></div>
                <div >{{title}}</div>
            </div>
            <Card :list="list" @onLoad="onLoad" :type="true" :finished="finished"  @handleClick ="handleClick" />   
        </div>
    </div>
</template>

<script>
import Card from '@/components/warranty_card.vue'
import {cardNumberList} from '@/api/_api/card';
export default {    
    components:{ Card },
    data(){
        return{
            list:[],
            finished:false,
            title:''
        }
    },
    methods:{
        handleClick(val){
            this.$router.push({path:'/warranty_detail',query:{id:val.cardNumber}})
        },
        onLoad(){},
        init(val){
            this.$toast.loading({
                message:'加载中...',
                forbidClick:true,
            })
            cardNumberList({insCompanyName:val}).then(res=>{
                if(res.code == 200){
                    res.data.forEach(item=>{
                        item.insCompanyLogo = JSON.parse(item.insCompanyLogo)
                    }) 
                    this.list = res.data
                    this.finished = true
                    
                }
                this.$toast.clear();
            })
        },
        
    },
    mounted(){
        this.title = this.$route.query.name
        this.init(this.title)
    }
}
</script>

<style lang="scss" scoped>
    .warranty{
        background: #f9f9f9;
        min-height: 100vh;
    }
    .list_box{
        min-height: 100vh;
        margin: vw(28) vw(24);
        .title{
            display: flex;
            align-items: center;
            font-size: vw(32);
            color: #333;
            font-weight: 600;
        }
        .atv{
            width: vw(4);
            height: vw(32);
            background: $indexcolor;
            margin-right: vw(14);
        }
    }
</style>